import * as React from "react"
import "./layout.css"
import {Link} from 'gatsby'

const Layout = () => {
  return (
    <>
    <center>
      <h1>Recruitment Blogs</h1>
      <h3>Coming Soon....</h3>
      <Link to="/demo-blog">
        Click Here...
      </Link>
    </center>
   
    </>
  )
}



export default Layout
